import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './New folder/navbar';
import Home from './New folder/home';
import About from './New folder/about'
import Terms from './New folder/terms'
import Contact from './New folder/contact';
import Error from './New folder/error'
function Page() {
  return (
    <Router>
        <Navbar />
      <Routes>
        <Route path='/*' element={<Error/>} />
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/contact' element={<Contact/>} />
      </Routes>
    </Router>  
  );
}

export default Page;
