
import React, { useState } from 'react';
import './contact.css';
import location from '../assets/vectors/location.svg';
import phone from '../assets/vectors/phone.png';
import mail from '../assets/vectors/email2.svg';
import time from '../assets/vectors/timing.png';
import emailjs from '@emailjs/browser';

function Contact() {
  const [form, setForm] = useState({
    name: '',
    email: '',
    number: '',
    message: '',
  });

  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (!form.name || !form.email || !form.number || !form.message) {
      setStatusMessage('Please fill out all fields.');
      return;
    }

    emailjs.send('service_gqm843m', 'template_oqy68oq', {
      user_name: form.name,
      user_email: form.email,
      user_number: form.number,
      user_message: form.message,
    }, 'PatjBCLKHV1Im0MWN')
    .then(
      (result) => {
        console.log('SUCCESS!', result.text);
        setStatusMessage('Your message has been sent successfully!');
        setForm({
          name: '',
          email: '',
          number: '',
          message: '',
        });
      },
      (error) => {
        console.error('FAILED...', error);
        setStatusMessage('Failed to send message. Please try again later.');
      }
    );
  };

  return (
    <div>
      <section className="contact-section" style={{overflow:'hidden'}}>
        <div className="contact-info"
        data-aos="fade-right"
        data-aos-duration="1000">
          <h2>Contact Us</h2>
          <div className="con1">
            <h3>Any questions? We would be happy to help!</h3>
          </div>
          <div className="add">
            <img src={location} alt="Location" />
            <h1>Address:</h1>
            <p>EDC CELL Panimalar Engineering College, Poonamallae Nazarethpet, Chennai</p>
          </div>
          <div className="email">
            <img src={mail} alt="Email" />
            <h1>Email:</h1>
            <p>allforonetech.in@gmail.com</p>
          </div>
          <div className="phone">
            <img src={phone} alt="Phone" />
            <h1>Phone Number:</h1>
            <p>9025486458, 9345670169, 9345520412</p>
          </div>
          <div className="hours">
            <img src={time} alt="Hours" />
            <h1>Open Hours:</h1>
            <p>Monday-Friday 9:00AM-5:00PM</p>
          </div>
        </div>

<div className="contact-form"
data-aos="fade-left"
data-aos-duration="1000">
          <form onSubmit={handleSubmit}>
            <div className='first-name'>
              <label htmlFor='name'>Name:</label>
              <input 
                type="text" 
                id="name"
                name="name" 
                value={form.name}
                onChange={handleChange}
                placeholder="Your name" 
                required
              />
            </div>
            <div className='fr-2'>
              <label htmlFor='email'>Email:</label>
              <input 
                type="email" 
                id="email"
                name="email" 
                placeholder="yourmail@gmail.com" 
                value={form.email} 
                onChange={handleChange}  
                required 
              />
              <label htmlFor='number'>Phone Number:</label>
              <input 
                type="tel" 
                id="number"
                name="number" 
                placeholder="9025486458" 
                value={form.number} 
                onChange={handleChange} 
                required 
              />
              <label htmlFor='message'>Message:</label>
              <textarea 
                id="message"
                name="message" 
                placeholder="Type your message here..." 
                value={form.message} 
                onChange={handleChange}
                required
              ></textarea> 
            </div>
            <button type="submit">Send Message</button>
          </form>
          {statusMessage && <p className="status-message">{statusMessage}</p>}
        </div>
      </section>
    </div>
  );
}

export default Contact;



