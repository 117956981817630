import React from 'react'

import './preloader.css'

function preloader() {
  return (
    <div className='preloader'>
   
    </div>
  )
}

export default preloader