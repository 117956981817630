import React from 'react'

function error() {
  return (
    <div style={{
        fontSize:'38px',
        color:'red',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontWeight:'bolder',
        textTransform:'capitalize',
        height:'60vh'


    }}>not found</div>
  )
}

export default error